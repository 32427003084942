/// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/pages/Member.js
// /var/www/website2024/192.testproject.work/website2024/s-303-happydebtclinic/admin/src/pages/Member.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import MemberTable from '../components/MemberTable';
import MemberForm from '../components/MemberForm';
import MemberSkeleton from '../components/MemberSkeleton';
import '../css/Member.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { ProgressSpinner } from 'primereact/progressspinner';

const Member = () => {
  const [memberData, setMemberData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentMember, setCurrentMember] = useState(null);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [formMode, setFormMode] = useState('add');
  const [isUserInteracting, setIsUserInteracting] = useState(false);
  const navigate = useNavigate();
  const user_ID = localStorage.getItem('user_ID') || 0;
  const [searchQuery, setSearchQuery] = useState('');
  const position_ID = useSelector((state) => state.user.positionID);
  const [totalMembers, setTotalMembers] = useState(0);
  const [displayedMembers, setDisplayedMembers] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(20);
  const cachedDataRef = useRef({});

  const handleCancelEdit = () => {
    setIsFormVisible(false);
  };

  const handleEditMember = (member) => {
    setCurrentMember(member);
    setFormMode('edit');
    setIsFormVisible(true);
  };

  const handleAddMember = () => {
    setCurrentMember(null);
    setFormMode('add');
    setIsFormVisible(true);
  };

  const parseSearchQuery = (query) => {
    const params = {};

    // Only proceed if the query has at least 3 characters
    if (query.length >= 3) {
      // Check for email
      if (query.includes('@')) {
        params.email = query;
      }
      // Check for phone number (5 or more digits)
      else if (/^\d{5,}$/.test(query)) {
        params.telephone = query;
      }
      // Otherwise, check for other criteria
      else {
        if (/[a-zA-Zก-๙]{4}/.test(query)) {
          // 4+ consecutive Thai or English characters - first_name & sur_name
          params.first_name = query;
          params.sur_name = query;
        }
        else if (/[a-zA-Zก-๙]{3}/.test(query)) {
          // 3+ consecutive Thai or English characters - first_name & sur_name
          params.first_name = query;
          params.sur_name = query;
        }
        else if (/\d{3,4}/.test(query)) {
          // 3-4 consecutive digits - user_ID_for
          params.user_ID_for = query;
        }
      }
    }

    return params;
  };


  const fetchMemberData = async (limit, offset = 0, query = '') => {
    const token = localStorage.getItem('token');
    const cacheKey = `${limit}-${offset}-${query}`;
  
    // Check cache
    if (cachedDataRef.current[cacheKey]) {
      setMemberData(prevData => [...prevData, ...cachedDataRef.current[cacheKey]]);
      return;
    }
  
    try {
      setIsLoading(true);
  
      const queryParams = new URLSearchParams();
      queryParams.append('user_ID', user_ID);
      queryParams.append('limit', limit);
      queryParams.append('offset', offset);
  
      const parsedQuery = parseSearchQuery(query);
      for (const [key, value] of Object.entries(parsedQuery)) {
        queryParams.append(key, value);
      }
  
      const apiUrl = (position_ID === 3 || position_ID === 4)
        ? `https://py-maracar.afaa.website/register/api_env_2/all_users_mlm_search?${queryParams.toString()}`
        : `https://py-maracar.afaa.website/register/api_env_2/user?user_ID=${user_ID}&limit=${limit}&offset=${offset}`;
  
      console.log(`API Request URL: ${apiUrl}`);
  
      const response = await fetch(apiUrl, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      const data = await response.json();
      if (data && Array.isArray(data.users)) {
        setMemberData(prevData => [...prevData, ...data.users]);
        setTotalMembers(data.total_count || 0);
        setDisplayedMembers(data.displayed_count || 0);
  
        // Cache the data
        cachedDataRef.current[cacheKey] = data.users;
      } else if (data && data.user_ID) {
        // Handle case where the response returns a single user object
        setMemberData([data]);
        setTotalMembers(1);
        setDisplayedMembers(1);
        cachedDataRef.current[cacheKey] = [data];
      } else {
        console.error('Unexpected API response:', data);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    } finally {
      setIsLoading(false);
    }
  };
  
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      navigate('/');
      return;
    }
  
    setMemberData([]);
    cachedDataRef.current = {};
    fetchMemberData(40, 0, searchQuery); // Fetch initial 40 items with search query
  }, [navigate, user_ID, position_ID, searchQuery]);
  
  const handlePageChange = (newPage) => {
    console.log(`Page: ${newPage}, Limit: ${rowsPerPage}, Offset: ${(newPage - 1) * rowsPerPage}`);
    setCurrentPage(newPage);
    fetchMemberData(rowsPerPage, (newPage - 1) * rowsPerPage, searchQuery); // Fetch items per page with search query
  };
  

  return (
    <div className="dashboard" onMouseEnter={() => setIsUserInteracting(true)} onMouseLeave={() => setIsUserInteracting(false)}>
      <Sidebar />
      <div className="dashboard-main">
        <Header
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          onBack={isFormVisible ? handleCancelEdit : undefined}
          handleAddMember={handleAddMember}
        />
        <div>
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="member-table-container mt-5">
                {isFormVisible ? (
                  <MemberForm
                    memberData={currentMember}
                    mode={formMode}
                    onCancel={handleCancelEdit}
                    setIsFormVisible={setIsFormVisible}
                    onTableRefresh={handlePageChange}
                  />
                ) : (
                  <div>
                    {isLoading ? (
                      <div className="d-flex justify-content-center">
                        <ProgressSpinner />
                      </div>
                    ) : (
                      <MemberTable
                        memberData={memberData}
                        onEditMember={handleEditMember}
                        token={localStorage.getItem('token')}
                        onTableRefresh={handlePageChange}
                        totalMembers={totalMembers}
                        setTotalMembers={setTotalMembers}
                        displayedMembers={displayedMembers}
                        onPageChange={handlePageChange}
                        currentPage={currentPage}
                        rowsPerPage={rowsPerPage}
                        setCurrentPage={setCurrentPage}
                        user_ID={user_ID}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Member;
