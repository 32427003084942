import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Header from '../components/Header';
import { OrganizationChart } from 'primereact/organizationchart';
import { ProgressSpinner } from 'primereact/progressspinner';
import { useLocation } from 'react-router-dom'; // Import useLocation
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../css/Member.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from '../css/MemberMLM.module.scss';  // Import the new SCSS module

import positionColors from '../data/OrgPositionsColor.json';

const MemberMLM = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const initialUserId = queryParams.get('userId') || localStorage.getItem('user_ID') || 57;

    const [searchQuery, setSearchQuery] = useState('');
    const [nodes, setNodes] = useState(null);
    const [history, setHistory] = useState([initialUserId]);  // Initialize with initialUserId
    const [zoom, setZoom] = useState(1);  // State to manage zoom level
    const [loading, setLoading] = useState(false);  // State to manage loading spinner
    const [totalMembers, setTotalMembers] = useState(0); // State to track total members

    const token = localStorage.getItem('token');

    // Function to count all nodes recursively
    const countNodes = (node) => {
        if (!node) return 0;
        let count = 1;
        if (node.children) {
            for (let child of node.children) {
                count += countNodes(child);
            }
        }
        return count;
    };

    // Function to fetch organization data
    const fetchOrgData = async (userId) => {
        setLoading(true);
        try {
            const response = await fetch(`https://py-maracar.afaa.website/mlm/api_env_2/view_org?user_id=${userId}&sort_order=asc`, {
                headers: { 'Authorization': `Bearer ${token}` },
            });
            if (response.ok) {
                const data = await response.json();
                if (data && data.length > 0) {
                    setNodes(data);  // Only update if data is not empty
                    setTotalMembers(countNodes(data[0])); // Update total members count
                } else {
                    console.log('No data received:', data);
                    setNodes([]);  // Set nodes to an empty array if no data is received
                    setTotalMembers(0); // Reset total members count
                }
            } else {
                console.error('Failed to fetch data:', response.statusText);
                setNodes([]);  // Set to empty array on error
                setTotalMembers(0); // Reset total members count
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setNodes([]);  // Set to empty array on error
            setTotalMembers(0); // Reset total members count
        } finally {
            setLoading(false);
        }
    };

    // Fetch data when component mounts and when userId changes
    useEffect(() => {
        fetchOrgData(initialUserId);
    }, [initialUserId, token]);

    // Click handler for nodes
    const handleClick = async (userId) => {
        setHistory(prevHistory => [...prevHistory, userId]);  // Add current userId to history
        await fetchOrgData(userId);  // Fetch data for the clicked user and update the chart
    };

    // Back handler
    const handleBack = async () => {
        setHistory(prevHistory => {
            const newHistory = [...prevHistory];
            newHistory.pop();  // Remove the last viewed userId
            const previousUserId = newHistory[newHistory.length - 1] || initialUserId;  // Get the previous userId or default to initial userId
            fetchOrgData(previousUserId);  // Fetch data for the previous userId
            return newHistory;
        });
    };

    const nodeTemplate = (node) => {
        if (!node) {
            return <div>Loading...</div>;  // Handle cases where node might be undefined or null
        }
        // Destructure the label object to access its properties
        const { first_name, sur_name, position, user_id } = node.label;
        
        // Set background color based on position
        const backgroundColor = positionColors.positions[position]?.color || '#FFFFFF';

        return (
            <div className={styles['node-container']} style={{ backgroundColor }} onClick={() => handleClick(user_id)}>
                <div>Name: {first_name} {sur_name}</div>
                <div>Position: {position}</div>
                <div>User ID: {user_id}</div>
            </div>
        );
    };

    // Function to zoom in
    const handleZoomIn = () => {
        setZoom(prevZoom => prevZoom + 0.1);
    };

    // Function to zoom out
    const handleZoomOut = () => {
        setZoom(prevZoom => Math.max(prevZoom - 0.1, 0.1));  // Ensure zoom level doesn't go below 0.1
    };

    return (
        <div className="dashboard">
            <Sidebar />
            <div className="dashboard-main">
                <Header
                    onBack={handleBack}  // Pass handleBack to Header
                    searchQuery={searchQuery}
                    setSearchQuery={setSearchQuery}
                />
                <div>
                    <h1>MLM Organization Chart</h1>
                    <div className={styles["zoom-controls"]}>
                        <button onClick={handleZoomIn} className="p-button p-component p-button-icon-only">
                            <span className="pi pi-search-plus"></span>
                        </button>
                        <button onClick={handleZoomOut} className="p-button p-component p-button-icon-only">
                            <span className="pi pi-search-minus"></span>
                        </button>
                    </div>
                    <div style={{ transform: `scale(${zoom})`, transformOrigin: 'top left' }}>
                        {loading ? (
                            <div className={styles["spinner-container"]}>
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
                            </div>
                        ) : (
                            nodes ? (
                                <div>
                                    <OrganizationChart value={nodes} nodeTemplate={nodeTemplate} className="mlm-chart" />
                                    <div className={styles["total-members"]}>
                                        Total Members: {totalMembers}
                                    </div>
                                </div>
                            ) : (
                                <div>Loading organization data...</div>  // Provide feedback while data is loading
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberMLM;
