// /var/www/website2023/py.testproject.work/s-293-spatime-admin/src/pages/Sidebar.js

import React, { useState,useEffect } from 'react';
import { useSelector } from 'react-redux';
import '../css/Sidebar.css'; // Ensure this path is correct for your CSS

const defaultImage = 'https://afaa.website/s/3dc61b.svg';
const userProfile = {
  name: `${localStorage.getItem('first_name')} ${localStorage.getItem('sur_name')}`,
  role: 'CMS SYSTEM',
  image: localStorage.getItem('image') || defaultImage, // Use default image if no image in localStorage
};

const Sidebar = () => {
  const [activeMenu, setActiveMenu] = useState('dashboard');
  const [imageSrc, setImageSrc] = useState(userProfile.image);
  const positionID = useSelector((state) => state.user.positionID);

  useEffect(() => {
    console.log('Redux positionID in Sidebar:', positionID);
  }, [positionID]);

  const handleImageError = () => {
    setImageSrc(defaultImage); // Fallback to default image if there's an error loading the image
  };

  const allMenuItems = [
    { name: 'Dashboard', icon: 'fa-tachometer-alt', path: '/Dashboard' },
    { name: 'MLM Organization', icon: 'fa-users', path: '/MemberMLM' },
    { name: 'Staff', icon: 'fa-users', path: '/Member' },
    { name: 'Services', icon: 'fa-spa', path: '/services' },
    { name: 'Type Services', icon: 'fa-solid fa-layer-group', path: '/typeservices' },
    { name: 'Logout', icon: 'fa-sign-out-alt', path: '/logout' },
  ];

  const restrictedMenuItems = [
    { name: 'Dashboard', icon: 'fa-tachometer-alt', path: '/Dashboard' },
    { name: 'MLM Organization', icon: 'fa-users', path: '/MemberMLM' },
    { name: 'Staff', icon: 'fa-users', path: '/Member' },
    { name: 'Logout', icon: 'fa-sign-out-alt', path: '/logout' },
  ];

  const menuItems = (positionID === 3 || positionID === 4) ? allMenuItems : restrictedMenuItems;

  const handleMenuClick = (menuName) => {
    setActiveMenu(menuName);
  };

  return (
    <aside className="sidebar">
      <div className="user-profile">
        <img
          src={imageSrc}
          alt={userProfile.name}
          className="user-image"
          onError={handleImageError}
        />
        <div className="user-info text-center">
          <h5 className="user-name">{userProfile.name}</h5>
          <p className="user-role">{userProfile.role}</p>
        </div>
      </div>

      <nav className="menu">
        {menuItems.map(item => (
          <a
            key={item.name}
            href={item.path}
            className={`menu-item ${activeMenu === item.name ? 'active' : ''}`}
            onClick={() => handleMenuClick(item.name)}
          >
            <i className={`fas ${item.icon}`}></i> {item.name}
          </a>
        ))}
      </nav>
    </aside>
  );
};

export default Sidebar;
